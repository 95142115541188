body {
    height: 100%; /* TODO: another way to get side to cover full height */
    margin: 0;
    padding: 0;
    background-color: #fff;
    font-family: Sofia-Pro, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    height: 100%; /* TODO: another way to get side to cover full height */
}

#root {
    height: 100%; /* TODO: another way to get side to cover full height */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
    color: black;
    text-decoration: none;
}

/* Suppress browser default of blue outline around Link components */
a:focus {
    outline: none;
}

svg {
    fill: currentColor;
}
